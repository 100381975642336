import React from 'react';
import PropTypes from 'prop-types';
import Authenticate from '../../../components/Identity/Authenticate';
import Layout from '../../../components/Admin/Layout';
import CatalogTabView from '../../../components/Admin/Browse/CatalogTabView';
import * as styles from '../../../styles/admin/Account.module.scss';
import browseTabs from '../../../browseTabs';
import { AdminProvider } from '../../../context/Admin/AdminContext';

function Catalog(props) {
  const { identityProviderClient } = props;
  return (
    <AdminProvider>
      <div className={styles.accountPageWrapper}>
        <Layout
          title="Browse Courses"
          Component={CatalogTabView}
          tabs={browseTabs}
          activeTab="Catalogue"
          identityProviderClient={identityProviderClient}
          moreInfo="<p className='text'>In this section, you can browse through all the courses available, assign new courses to Learners and approve the courses requested by learners.</p>"
        />
      </div>
    </AdminProvider>
  );
}
Catalog.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authenticate(Catalog);
